const prod = {
  base: 'https://api.eatellia.com',
  photos: 'https://photos.eatellia.com'
};

const dev = {
  base: 'http://dev.relgames.org',
  photos: 'http://dev-photos.relgames.org'
};

const localhost = {
  base: 'http://localhost:8081',
  photos: 'http://dev-photos.relgames.org'
};

let api;

if (process.env.NODE_ENV === 'production') {
  api = prod;
} else if (process.env.NODE_ENV === 'dev') {
  api = dev;
} else {
  api = localhost;
}

export default api;
