import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import TokenContext from './TokenContext';
import axios from 'axios';
import api from './api';
import Photo from './Photo';
import Typography from '@material-ui/core/Typography';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import DoneIcon from '@material-ui/icons/Done';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },

  gridList: {
    [theme.breakpoints.down('md')]: {
      width: 400
    },
    [theme.breakpoints.up('md')]: {
      width: 800
    }
  },

  footer: {
    display: 'flex',
    justifyContent: 'center'
  }
});

function Feed(props) {
  const token = useContext(TokenContext);

  const axiosInstance = axios.create({
    baseURL: api.base,
    headers: { 'x-bb-session': token }
  });

  const { classes, theme } = props;

  const [page, setPage] = useState(0);
  const [feed, setFeed] = useState([]);
  const [adminTags, setAdminTags] = useState([]);

  function loadAdminTags() {
    axiosInstance
      .get('/admin/tags')
      .then(response => setAdminTags(response.data))
      .catch(e => console.error('Could not load tags', e));
  }

  useEffect(() => {
    loadAdminTags();
  }, []);

  const large = useMediaQuery(theme.breakpoints.up('md'));
  const cols = large ? 2 : 1;

  function updateFeed(response) {
    setFeed(feed.concat(response.data));
  }

  useEffect(() => {
    axiosInstance
      .get('/admin/feed', { params: { page: page } })
      .then(response => updateFeed(response))
      .catch(e => console.error('Could not load feed', e));
  }, [page]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [page]);

  function handleScroll() {
    if (
      window.innerHeight + window.pageYOffset >=
      document.body.offsetHeight - 1
    ) {
      console.debug('Loading next page, current page', page);
      setPage(page + 1);
    }
  }

  const adminTagsDenormalised = {
    cuisines: adminTags.filter(t => t.tagType === 'cuisine').map(t => t.tag),
    foodTypes: adminTags.filter(t => t.tagType === 'food_type').map(t => t.tag),
    others: adminTags.filter(t => t.tagType === 'other').map(t => t.tag)
  };

  function handleTags(postId, tags) {
    console.debug('Updating tags for post', postId, tags);

    axiosInstance
      .put('/admin/post/' + postId + '/tags', { tags: tags })
      .catch(e => console.error('Could not update tags', e));
  }

  function removePostGroup(postGroupId) {
    const newFeed = feed.filter(post => post.id !== postGroupId);
    setFeed(newFeed);
  }

  function handleFinish(postGroupId) {
    console.debug('Finished post group', postGroupId);

    axiosInstance
      .put('/admin/postGroup/' + postGroupId + '/finish')
      .then(response => removePostGroup(postGroupId))
      .catch(e => console.error('Could not finish', e));
  }

  function renderPost(post) {
    return [
      <GridListTile
        key={post.id + '-header'}
        cols={cols}
        style={{ height: 'auto' }}
      >
        <ListSubheader component="div">
          <Typography variant="h4" gutterBottom>
            {post.placeName}
          </Typography>
        </ListSubheader>
      </GridListTile>,

      ...post.checkins.map(checkin => (
        <GridListTile key={checkin.photo}>
          {checkin.name && (
            <GridListTileBar title={checkin.name} titlePosition="top" />
          )}
          <Photo
            checkin={checkin}
            adminTags={adminTagsDenormalised}
            handleTags={tags => handleTags(checkin.id, tags)}
          />
        </GridListTile>
      )),

      <GridListTile key={post.id + '-footer'} cols={cols}>
        <ListSubheader component="div" className={classes.footer}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleFinish(post.id)}
          >
            <DoneIcon /> FINISH
          </Button>
        </ListSubheader>
      </GridListTile>
    ];
  }

  return (
    <div className={classes.root}>
      <GridList
        cellHeight="auto"
        className={classes.gridList}
        cols={cols}
        onScroll={handleScroll}
      >
        {feed.map(post => renderPost(post))}
      </GridList>
    </div>
  );
}

Feed.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Feed);
