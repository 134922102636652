import React, { useEffect, useState } from 'react';
import Login from './Login';
import MenuAppBar from './MenuAppBar';
import TokenContext from './TokenContext';

function Admin() {
  const initialToken = () => localStorage.getItem('token');

  const [token, setToken] = useState(initialToken);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  });

  if (!token) {
    return <Login onSuccess={setToken} />;
  } else {
    return (
      <TokenContext.Provider value={token}>
        <MenuAppBar handleLogout={() => setToken(null)} />
      </TokenContext.Provider>
    );
  }
}

export default Admin;
