import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import { Paper, withStyles } from '@material-ui/core';
import api from './api';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {
  container: {
    padding: '1px'
  },

  photo: {
    width: '400px'
  },

  checkboxContainer: {
    marginLeft: '15px'
  },

  tagContainer1: {
    marginTop: '5px',
    backgroundColor: '#f3fdff'
  },
  tagContainer2: {
    marginTop: '10px',
    backgroundColor: '#eeefff'
  },
  tagContainer3: {
    marginTop: '10px',
    backgroundColor: '#ecffe7'
  },

  checkbox: {
    padding: '0px',
    margin: '0px'
  }
};

function Photo(props) {
  const { classes, checkin, handleTags, adminTags } = props;

  const [tagsAdmin, setTagsAdmin] = useState(checkin.tagsAdmin);

  const handleTagChange = (tag, value) => {
    let newTags;
    if (!value) {
      newTags = tagsAdmin.filter(t => t !== tag);
    } else {
      newTags = [...tagsAdmin, tag];
    }

    setTagsAdmin(newTags);
    handleTags(newTags);
  };

  function checkbox(tag) {
    return (
      <FormControlLabel
        control={<Checkbox className={classes.checkbox} />}
        value={tag}
        label={tag}
        key={tag}
        checked={tagsAdmin.includes(tag)}
        onChange={event => handleTagChange(tag, event.target.checked)}
      />
    );
  }

  return (
    <div className={classes.container}>
      <img
        src={api.photos + checkin.photo}
        alt={checkin.name}
        className={classes.photo}
      />

      <Paper className={classes.tagContainer1}>
        <div className={classes.checkboxContainer}>
          {adminTags.cuisines.map(c => checkbox(c))}
        </div>
      </Paper>
      <Paper className={classes.tagContainer2}>
        <div className={classes.checkboxContainer}>
          {adminTags.foodTypes.map(c => checkbox(c))}
        </div>
      </Paper>
      <Paper className={classes.tagContainer3}>
        <div className={classes.checkboxContainer}>
          {adminTags.others.map(c => checkbox(c))}
        </div>
      </Paper>
    </div>
  );
}

Photo.propTypes = {
  classes: PropTypes.object.isRequired,
  checkin: PropTypes.object.isRequired,
  handleTags: PropTypes.func.isRequired,
  adminTags: PropTypes.object.isRequired
};

export default withStyles(styles)(Photo);
