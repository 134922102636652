import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TokenContext from './TokenContext';
import axios from 'axios';
import api from './api';
import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import _ from 'lodash';

const styles = theme => ({
  root: {
    padding: 0
  },

  paper: {
    maxWidth: 1500,
    margin: 'auto',
    padding: theme.spacing.unit * 2
  },

  table: {
    minWidth: 650,
    tableLayout: 'auto'
  },

  fullWidth: {
    width: '100%'
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2
  },

  leftIcon: {
    marginRight: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  }
});

// state format:
// const example = [
//   {
//     "key": "", // when new row is added
//     "backend": // can be absent
//       {
//         "tag": "japanese",
//         "tagType": "cuisine",
//         "translations": {
//           "RUSSIAN": "японская кухня"
//         },
//         "alternatives": [
//           "japan",
//           "японская"
//         ]
//       },
//     "ui": {   // always there
//       // same as backend
//     }
//   }
// ];

function isTranslationChanged(tag, translation) {
  if (!tag.backend) {
    return false;
  }

  const oldTranslation = tag.backend.translations[translation] || '';
  const newTranslation = tag.ui.translations[translation] || '';

  return oldTranslation !== newTranslation;
}

function TagsAdmin(props) {
  const { classes } = props;

  const token = useContext(TokenContext);

  const axiosInstance = axios.create({
    baseURL: api.base,
    headers: { 'x-bb-session': token }
  });

  const [tags, setTags] = useState([]);

  function handleBackendTags(backendTags) {
    const newState = backendTags.map(t => ({
      backend: _.cloneDeep(t),
      ui: _.cloneDeep(t)
    }));

    setTags(newState);
  }

  function loadTags() {
    axiosInstance
      .get('/admin/tags')
      .then(response => handleBackendTags(response.data))
      .catch(e => console.error('Could not load tags', e));
  }

  function updateTag(tag) {
    return axiosInstance
      .put('/admin/tags/' + tag.backend.tag, tag.ui)
      .catch(e => console.error('Could not update tag', tag, e));
  }

  function addTag(tag) {
    return axiosInstance
      .post('/admin/tags', tag.ui)
      .catch(e => console.error('Could not add tag', tag, e));
  }

  function deleteTag(tag) {
    return axiosInstance
      .delete('/admin/tags/' + tag.backend.tag)
      .catch(e => console.error('Could not delete tag', tag, e));
  }

  useEffect(() => {
    loadTags();
  }, []);

  function handleTagChange(i, tag) {
    console.debug('Changing tag', tags[i].ui.tag, '=>', tag);

    const tagsCopy = _.cloneDeep(tags);
    tagsCopy[i].ui.tag = tag;

    setTags(tagsCopy);
  }

  function handleTranslationChange(i, translation, tag) {
    console.debug(
      'Changing translation',
      tags[i].ui.tag,
      translation,
      '=>',
      tag
    );

    const tagsCopy = _.cloneDeep(tags);
    tagsCopy[i].ui.translations[translation] = tag;

    setTags(tagsCopy);
  }

  function handleAlternativesChange(i, alternatives) {
    console.debug(
      'Changing tag alternatives',
      tags[i].ui.tag,
      '=>',
      alternatives
    );

    const tagsCopy = _.cloneDeep(tags);
    tagsCopy[i].ui.alternatives = alternatives;

    setTags(tagsCopy);
  }

  function alternativesToText(alternatives) {
    return alternatives.join(', ');
  }

  function textToAlternatives(text) {
    return text.split(/, */).filter(t => t);
  }

  function handleFoodTypeChange(i, tagType) {
    console.debug(
      'Changing tagType',
      tags[i].ui.tag,
      tags[i].ui.tagType,
      '=>',
      tagType
    );

    // deep copy
    const tagsCopy = tags.map(o => ({ ...o }));
    tagsCopy[i].ui.tagType = tagType;

    setTags(tagsCopy);
  }

  function handleAddNew() {
    // deep copy
    const tagsCopy = tags.map(o => ({ ...o }));

    tagsCopy.push({
      key: 'new-' + tags.length,
      ui: {
        tag: '',
        tagType: 'cuisine',
        translations: {},
        alternatives: []
      }
    });

    setTags(tagsCopy);
  }

  function handleSave() {
    const updateTags = tags.filter(
      t => t.backend && t.ui.tag && !_.isEqual(t.backend, t.ui)
    );
    const addTags = tags.filter(t => !t.backend && t.ui.tag !== '');
    const deleteTags = tags.filter(t => t.backend && t.ui.tag === '');

    console.debug('Update tags', updateTags);
    console.debug('Add tags', addTags);
    console.debug('Delete tags', deleteTags);

    const all = [];

    updateTags.forEach(t => all.push(updateTag(t)));
    addTags.forEach(t => all.push(addTag(t)));
    deleteTags.forEach(t => all.push(deleteTag(t)));

    Promise.all(all).then(() => loadTags());
  }

  // TableCell 'align' is from material-ui, not deprecated
  // noinspection HtmlDeprecatedAttribute
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table className={classes.table} padding="dense">
          <colgroup>
            <col width="150px" />
            <col width="200px" />
            <col />
            <col width="150px" />
          </colgroup>

          <TableHead>
            <TableRow>
              <TableCell>Tag</TableCell>
              <TableCell>Russian</TableCell>
              <TableCell>Alternatives</TableCell>
              <TableCell align="right">Type</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tags.map((t, i) => (
              <TableRow key={t.key || t.backend.tag}>
                <TableCell component="th" scope="row">
                  <TextField
                    defaultValue={t.ui.tag}
                    onBlur={event => handleTagChange(i, event.target.value)}
                    error={t.backend && t.backend.tag !== t.ui.tag}
                    className={classes.fullWidth}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    defaultValue={t.ui.translations['RUSSIAN']}
                    onBlur={event =>
                      handleTranslationChange(i, 'RUSSIAN', event.target.value)
                    }
                    error={isTranslationChanged(t, 'RUSSIAN')}
                    className={classes.fullWidth}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    defaultValue={alternativesToText(t.ui.alternatives)}
                    onBlur={event =>
                      handleAlternativesChange(
                        i,
                        textToAlternatives(event.target.value)
                      )
                    }
                    error={
                      t.backend &&
                      !_.isEqual(t.backend.alternatives, t.ui.alternatives)
                    }
                    className={classes.fullWidth}
                  />
                </TableCell>
                <TableCell align="right">
                  <NativeSelect
                    value={t.ui.tagType}
                    onChange={event =>
                      handleFoodTypeChange(i, event.target.value)
                    }
                    error={t.backend && t.ui.tagType !== t.backend.tagType}
                    className={classes.fullWidth}
                  >
                    <option value="cuisine">Cuisine</option>
                    <option value="food_type">Food type</option>
                    <option value="other">Other</option>
                  </NativeSelect>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <div className={classes.buttonContainer}>
          <Button onClick={handleAddNew}>
            <AddIcon
              className={classNames(classes.leftIcon, classes.iconSmall)}
            />
            Add new
          </Button>

          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
            <SaveIcon
              className={classNames(classes.rightIcon, classes.iconSmall)}
            />
          </Button>
        </div>
      </Paper>
    </div>
  );
}

TagsAdmin.propTypes = {};

export default withStyles(styles)(TagsAdmin);
